import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { AlertSeverityComponent } from '../../../alerts/components/alert-severity/alert-severity.component';
import { FormatPipe } from '../../../shared/pipes/format.pipe';
import { SiteIconComponent } from '../../../site-detail/components/icon/site-icon.component';
import { SiteId } from '../../../site-detail/models/Site';
import { FavoriteSite } from '../../models/favorite-site';
import { FavoriteSitesActions } from '../../store/actions/favorite-site';

@Component({
    selector: 'app-favorite-item',
    imports: [SiteIconComponent, AlertSeverityComponent, FormatPipe, RouterLink],
    templateUrl: './favorite-item.component.html',
    styleUrl: './favorite-item.component.scss',
})
export class FavoriteItemComponent {
    @Input() favoriteSite: FavoriteSite;

    constructor(private store: Store<FavoriteSite>) {}

    onDeleteFavorite($event: Event, siteId: SiteId): void {
        $event.stopPropagation();
        $event.preventDefault();
        this.store.dispatch(FavoriteSitesActions.favoriteRemoved({ siteId }));
    }
}
