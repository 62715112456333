import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import type { LinkRequestOption } from '../link-request-modal/LinkRequestOption';
import { ModalLinkRequestComponent } from '../link-request-modal/modal-link-request.component';

@Component({
    selector: 'app-create-link-request-button',
    imports: [TranslateModule],
    templateUrl: './create-link-request-button.component.html',
    styleUrl: './create-link-request-button.component.scss',
})
export class CreateLinkRequestButtonComponent {
    constructor(private dialog: MatDialog) {}

    onLinkRequestClick(): void {
        const data: LinkRequestOption = {
            enduserEmail: '',
            gatewayId: '',
            readonly: false,
        };

        this.dialog.open(ModalLinkRequestComponent, { data });
    }
}
