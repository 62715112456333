import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

export enum SomfyproLogoComponentVariant {
    SIZE_BIG = 'size-big',
}

@Component({
    selector: 'app-somfypro-logo',
    templateUrl: './somfypro-logo.component.html',
    styleUrls: ['./somfypro-logo.component.scss'],
    imports: [NgClass],
})
export class SomfyproLogoComponent {
    @Input() variant: SomfyproLogoComponentVariant;
    @Input() url: string;
}
