import { createSelector } from '@ngrx/store';
import { getNbPendingAlerts } from '../../../alerts/store/selectors/alerts';
import { countLinkRequests } from '../../../link-requests/store/selectors/link-requests';
import { getUISiteMaxCount } from '../../../site-list/store/selectors/ui';

export const countResults = createSelector(
    getUISiteMaxCount,
    getNbPendingAlerts,
    countLinkRequests,
    (nbSites, nbAlerts, nbLinkRequests) => ({ nbSites, nbAlerts, nbLinkRequests }),
);
