import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { SharedModule } from '../../../shared/shared.module';
import { AppState } from '../../../shared/store/app-state';
import { countResults } from '../../store/selectors/search';

type Link = {
    path: string;
    label: string;
};

@Component({
    selector: 'app-tab-navigation',
    templateUrl: './tab-navigation.component.html',
    styleUrls: ['./tab-navigation.component.scss'],
    imports: [CommonModule, SharedModule, TranslateModule],
})
export class TabNavigationComponent implements OnInit {
    private unsubscribe$: Subject<void> = new Subject();

    protected readonly links: Link[] = [
        { path: 'sites', label: 'ALL_INSTALLATIONS' },
        { path: 'alerts', label: 'INSTALLATIONS_IN_ALERT' },
        { path: 'link-requests', label: 'PENDING_REQUESTS' },
    ];
    protected activeLink: string;
    protected nbResults = {
        sites: 0,
        alerts: 0,
        'link-requests': 0,
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private store: Store<AppState>,
        private ref: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.store
            .select(countResults)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(({ nbSites: sites, nbAlerts: alerts, nbLinkRequests }) => {
                this.nbResults = { sites, alerts, 'link-requests': nbLinkRequests };
                this.ref.detectChanges();
            });

        this.route.firstChild?.url.subscribe((urlSegments) => {
            const path = urlSegments[0]?.path;
            const link = this.links.find((link) => link.path === path);
            this.activeLink = link ? link.path : this.links[0].path;
        });

        this.router.events
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((event) => event instanceof NavigationEnd),
                map(({ url }: NavigationEnd) => {
                    const link = this.links.find((link) => '/' + link.path === url);
                    this.activeLink = link ? link.path : this.links[0].path;
                    this.ref.detectChanges();
                }),
            )
            .subscribe();
    }

    async navigateTo(link: Link) {
        await this.router.navigate([link.path], { relativeTo: this.route });
    }
}
